<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/03-10主数据管理系统.png"/>
        <div class="mobile-head-explain">
            <div class="mobile-head-explain-title" >主数据管理系统</div>
            <div class="mobile-head-explain-content">全球某医疗和实验室产品的制造商和分销商</div>
        </div>
        <div class="description">
            <div class="title1">项目介绍</div>
            <div v-if="!showDescription" class="title2" @click="(showDescription = !showDescription)">展开<i class="el-icon-arrow-down"></i></div>
            <div v-if="showDescription" class="title2" @click="(showDescription = !showDescription)">收起<i class="el-icon-arrow-up"></i></div>
        </div>
        <div style="margin-top: 2rem;">
            <el-collapse-transition>
                <div v-if="showDescription">
                    <div class="description-box">为了使制药业现代化并与国际标准接轨，客户公司进行了重大的监管改革。按照GSP标准，在药品仓储，分
                销和物流领域，需要建立一套完整的数据治理系统，来应对定期/不定期的内部和外部审计。<br/>
                通过帮助用户标准化其业务规则、工作流程和审批流程，实现整个企业内各类主数据的治理。另外，通过面向服务的架构（SOA），提供了很强的适应
                性、易用性和快速部署的能力。</div>
                </div>
            </el-collapse-transition>
        </div>
        <div class="mobile-head-catalogue" style="margin-top:5.7rem">
            <!-- <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>业务痛点</span></div> -->
            <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>实施方案</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>实施收益</span></div>
        </div>
        <div v-if="(checkIndex==2)" class="mobile-content-box1">
            <div class="ware-content1" style="margin-top:2rem;margin-bottom: 2rem;">逐步实现企业的主数据管理“统一标准、集中管控、分级授权、专业管理”目标，实现主数据在研制两端融汇贯通
                    ，清晰规划各层面主数据应用规范和主数据管理职责，建立职责清晰、流程严密、长期且有效的主数据管理体系，在保证数据质量的前提下，实
                    现主数据在下游系统中的高效利用。</div>
            <div class="purchase-box">
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-3-5方便创建.svg"/>
                        <div>
                            <div class="title1">方便创建</div>
                            <div class="title2">轻松、方便地创建或修改主数据。</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/05-3-5灵活管理.svg"/>
                        <div>
                            <div class="title1">灵活管理</div>
                            <div class="title2">在规范数据应用的前提下，又能灵活地管理工作流程。</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-3-5数据治理.svg"/>
                        <div>
                            <div class="title1">数据治理</div>
                            <div class="title2">通过数据清洗，校验规则实现数据治理。</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-3-5可追溯.svg"/>
                        <div>
                            <div class="title1">可追溯可审计</div>
                            <div class="title2">任何主数据变更都会留下记录，实现可追溯和可审计。</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/05-3-5无缝对接.svg"/>
                        <div>
                            <div class="title1">无缝对接</div>
                            <div class="title2">无缝的上下游系统数据对接。</div>
                        </div>
                    </div>
                    <div style="width: 34.8rem;height: 15rem;">

                    </div>
                </div>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1">
            <div class="purchase-title" style="display:flex;padding-top: 2rem;"><div class="purchase-doit">●</div><div class="special-style">完成GSP合规认证；</div></div>
            <div class="purchase-title" style="display:flex;"><div class="purchase-doit">●</div><div class="special-style">提升流程的透明化，从而减少新增/变更主数据84%的申请时间；</div></div>
            <div class="purchase-title" style="display:flex;"><div class="purchase-doit">●</div><div class="special-style">减少了人工干预，以及避免所带来的错误；</div></div>
            <div class="purchase-title" style="display:flex;"><div class="purchase-doit">●</div><div class="special-style">为下游系统及时提供有效准确的数据，继而加快了业务决策；</div></div>
            <div class="purchase-title" style="display:flex;margin-bottom: 5rem;"><div class="purchase-doit">●</div><div class="special-style">针对核心指标建立数据报表，为后续分析及流程完善打下良好的基础。</div></div>
            
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 2,
            showDescription:true,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
        };
    },

    mounted() {
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 3.3rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 65%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
    text-align: justify;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 20rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
    text-align: justify;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    height: 10rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        // height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem; 
        margin: auto; 
        align-items: center;
    }
}
.ware-img{
    width: 66.5rem;
    height: 43.5rem;
}
.ware-img2{
    width: 66.5rem;
    height: 28rem;
}
.purchase-box{
    width: 100%;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;
    
    .card{
        width: 52.8rem;
        height: 18rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin: auto;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 6rem;
            height: 6rem;
            margin: 4.5rem 0 0 2rem;
        }
        .title1{
            width: 100%;
            font-size: 2.8rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 5rem;

            margin: 3rem 0 0 2.1rem;
        }
        .title2{
            width: 38rem;
            // height: 7.2rem;
            font-size: 2.8rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 4rem;

            margin: 0rem 0 0 2.1rem;
        }
    }
}
.special-style{
    max-width: 66.5rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
    display: flex;
    .title-6{
        text-align: center;
        margin: auto;
        width: 5rem;
        height: 5rem;
        font-size: 4rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #ED5847;
        line-height: 3rem;
        margin-top: -0.5rem;
    }
}
.purchase-title{
    max-width: 66.5rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 2rem 0 0 0.2rem;
}
.purchase-doit{
    color: #f76f7c;
    font-size: 1rem;
    transform: scale(1);
    margin-right: 1.4rem;
}
</style>